<template>
  <transition
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    enter-active-class="transform ease-out duration-300 transition"
    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    leave-to-class="opacity-0"
    leave-active-class="transition ease-in duration-100"
    leave-class="opacity-100"
  >
    <div
      @click="isPlayingMusic = false"
      v-if="isPlayingMusic"
      class="max-w-sm w-full bg-gray-900 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- Heroicon name: outline/check-circle -->
            <img
              class="w-12 h-12 mx-auto rounded-md"
              :src="trackAlbumArt"
              alt=""
              width="384"
              height="512"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-bold text-white">
              {{ trackName }}
            </p>
            <p class="text-sm text-gray-300">
              {{ artistName }}
            </p>
            <p class="mt-1 text-xs text-gray-300">
              {{ album }}
            </p>
          </div>

          <div class="flex-shrink-1">
            <!-- Heroicon name: outline/check-circle -->
            <div class="equaliser-container">
              <ol class="equaliser-column">
                <li class="colour-bar"></li>
              </ol>
              <ol class="equaliser-column">
                <li class="colour-bar"></li>
              </ol>
              <ol class="equaliser-column">
                <li class="colour-bar"></li>
              </ol>
              <ol class="equaliser-column">
                <li class="colour-bar"></li>
              </ol>
              <ol class="equaliser-column">
                <li class="colour-bar"></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  created() {
    let self = this;
    this.$lanyard({
      userId: "356139270446120960",
    }).then((response) => {
      if (response.success == true) {
        console.log("Success", response.data);
        if (response.data.listening_to_spotify == true) {
          self.artistName = response.data.spotify.artist;
          self.trackName = response.data.spotify.song;
          self.album = response.data.spotify.album;
          self.trackAlbumArt = response.data.spotify.album_art_url;
          self.isPlayingMusic = true;
        }
      }
    });
  },
  data() {
    return {
      isPlayingMusic: false,
      artistName: null,
      trackName: null,
      album: null,
      trackAlbumArt: null,
    };
  },
};
</script>

<style>
.equaliser-container {
  height: 10px;
  width: 20px;
  margin: 0 0;
  padding: 0 0 0 0;
  position: relative;
}

.colour-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background: #999797;
}

.equaliser-column {
  width: 2px;
  float: left;
  margin: 0 1px 0 0;
  padding: 0;
  height: 10px;
  position: relative;
  list-style-type: none;
}

.equaliser-column:nth-child(1) .colour-bar {
  animation: color-bar 2s 1s ease-out alternate infinite;
}

.equaliser-column:nth-child(2) .colour-bar {
  animation: color-bar 2s 0.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(3) .colour-bar {
  animation: color-bar 2s 1.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(4) .colour-bar {
  animation: color-bar 2s 0.25s ease-out alternate infinite;
}

.equaliser-column:nth-child(5) .colour-bar {
  animation: color-bar 2s 2s ease-out alternate infinite;
}

.equaliser-column:last-child {
  margin-right: 0;
}

@keyframes color-bar {
  0% {
    height: 1px;
    background: #999797;
  }
  10% {
    height: 3px;
    background: #999797;
  }
  20% {
    height: 5px;
    background: #999797;
  }
  30% {
    height: 2px;
    background: #999797;
  }
  40% {
    height: 7px;
    background: #999797;
  }
  50% {
    height: 9px;
    background: #999797;
  }
  60% {
    height: 3px;
    background: #999797;
  }
  70% {
    height: 8px;
    background: #999797;
  }
  80% {
    height: 5px;
    background: #999797;
  }
  90% {
    height: 3px;
    background: #999797;
  }
  100% {
    height: 1px;
    background: #999797;
  }
}
</style>
