import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'
import VueLanyard from "@eggsydev/vue-lanyard";
import VueClipboard from 'vue-clipboard2'
import SimpleAnalytics from "simple-analytics-vue";

Vue.use(SimpleAnalytics, { domain: "sa.ms7m.me", skip: process.env.NODE_ENV !== "production" });
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(VueLanyard);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

console.log("I'm not a frontend developer, so please have some mercy around here. :)")