<template>
  <div class="flex flex-col h-screen">
    <main class="flex-grow">
      <div class="absolute p-16 inset-0 flex items-center">
        <div class="w-full text-left items-center max-w-2xl">
          <div class="w-36 block">
            <img
              src="https://github.com/ms7m.png"
              alt="..."
              class="shadow-lg rounded-full max-w-full h-auto align-middle border-none"
            />
          </div>
          <div class="font-bold text-3xl text-gray-200 mt-2">
            Mustafa Mohamed
          </div>
          <div
            class=" flex transition-all duration-500 ease-in-out font-md break-normal text-md md:text-xl text-gray-200 mt-2 mb-8"
          >
            👋🏽 Thanks for stopping by!
          </div>
          <div
            class="font-normal break-normal text-sm md:text-xl text-gray-300 mt-2 mb-5"
          >
            I'm 19, based in RTP, NC and I'm currently employed as a
            <strong>
              Network Infrastructure Automation engineer at a
            </strong>
            certain Fortune 500 Company.
            <div class="pt-2 text-sm">
              I'm currently
              <a class="text-redishPink font-bold">not looking</a> for
              employment, but I'm always interested in new opportunities.
            </div>
          </div>

          <div class="mt-5">
            <router-link to="/email">
              <button
                type="button"
                class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  flex  inline-flex bg-redishPink text-darkBlueBlack font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
              >
                <div class="pr-2">
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    ></path>
                  </svg>
                </div>
                Email
              </button>
            </router-link>
            <router-link to="/skills" class=""
              ><button
                type="button"
                class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  mt-2 flex  inline-flex bg-redishPink text-darkBlueBlack font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
              >
                <div class="pr-2">
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    ></path>
                  </svg>
                </div>
                What can I do?
              </button></router-link
            >
            <a href="https://github.com/ms7m" class=""
              ><button
                type="button"
                class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  mt-2 flex  inline-flex bg-redishPink text-darkBlueBlack font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
              >
                <div class="pr-2">
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>GitHub icon</title>
                    <path
                      d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
                    />
                  </svg>
                </div>
                GitHub
              </button></a
            >
          </div>
        </div>
      </div>
    </main>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
      aria-live="assertive"
      class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
    >
      <now-playing></now-playing>
    </div>
  </div>
</template>

<script>
import nowPlaying from "../components/nowPlaying.vue";
// @ is an alias to /src

export default {
  components: { nowPlaying },
  name: "Home",
  data() {
    return {
      isCopiedNotificationShow: false,
    };
  },
  methods: {
    openEmailClient() {
      var encodedBaseString = "bXVzdGFmYUBtczdtLm1l";
      open(`mailto:${atob(encodedBaseString)}`, "_blank");
    },
    copyEmailToClipboard() {
      var encodedBaseString = "bXVzdGFmYUBtczdtLm1l";
      let container = this.$refs.container;
      this.$copyText(atob(encodedBaseString), container);
      this.isCopiedNotificationShow = true;
      setTimeout(() => (this.isCopiedNotificationShow = false), 5000);
    },
  },
};
</script>
